<template>
  <div class="container reserve">
    <div v-if="!isShowEdit" class="part">
      <div class="actions_part clearfix">
        <div class="actions_wrap">
          <div class="display_ib mr10 mb10">
            <span class="tag">预留单号：</span>
            <el-input v-model="reserveId" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">业务编号：</span>
            <el-input v-model="saleOrderId" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">物料编号：</span>
            <el-input v-model="materialCode" class="input_single w200 mr10" placeholder="请输入" @keyup.enter.native="searchHandle"/>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">预留类型：</span>
            <el-select
              v-model="reserveType"
              class="select_single w200 mr10"
              placeholder="请选择"
              @change="searchHandle">
              <el-option key label="全部" value/>
              <el-option
                v-for="item in reserveTypeList"
                :key="item.dictItemValue"
                :label="item.dictItemName"
                :value="item.dictItemValue"
              />
            </el-select>
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">期望交期：</span>
            <el-date-picker
              v-model="dateVal"
              class="mr10"
              unlink-panels
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateValChange"
            />
          </div>
          <div class="display_ib mr10 mb10">
            <span class="tag">计划调拨时间：</span>
            <el-date-picker
              v-model="latestTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </div>
        </div>
        <div class="actions_btn_wrap down t_right">
          <el-button type="primary" @click="excelDownload">导出Excel</el-button>
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            circle
            title="搜索"
            @click="searchHandle"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            circle
            title="添加"
            @click="addHandle"
          />
        </div>
      </div>

      <div class="table_part clearfix">
        <el-tabs v-model="activeName" class="subnav_wrap" @tab-click="switchTabHandle">
          <el-tab-pane
            v-loading="tableLoading"
            v-for="item in subNavList"
            :key="item.value"
            :label="item.label"
            :name="item.value">
            <!-- <Table
              :tableList="reserveList"
              :parentData="parentData"
              :childData="childData"
              :tableFieldsList="allFieldList"
              :tableParentFieldsList="parentFieldsList"
              :tableLoading="tableLoading"
              @editHandle="editHandle"
              @submitHandle="submitHandle"
              @removeHandle="removeHandle"
              @deleteHandle="deleteHandle"
              @removeItemHandle="removeItemHandle">
            </Table> -->

            <el-table
              v-loading="listLoading"
              ref="multipleTable"
              :data="reserveList"
              :expand-row-keys="expands"
              class="table_list"
              row-key="id"
              border
              fit
              stripe
              @row-click="rowClick"
              @expand-change="rowClick">
              <!-- <el-table-column type="selection" /> -->
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <div class="actions_wrap mb20 clearfix">
                    <el-button class="fr" type="primary" @click="childExcelDownload(scope.row)">导出Excel</el-button>
                    <el-button v-if="scope.row.auditStatus == 'Draft' || scope.row.auditStatus == 'Rejected'" class="fr mr10" type="primary" @click="editHandle(scope.$index, scope.row)">更新导入Excel</el-button>
                  </div>
                  <el-table
                    v-loading="childListLoading"
                    :ref="'childMultipleTable_' + scope.row.id"
                    :data="scope.row.planReserveItemList"
                    class="table_list"
                    border
                    fit>
                    <!-- <el-table-column fixed type="selection" /> -->
                    <el-table-column fixed label="行号">
                      <template slot-scope="{ row }">
                        {{ row.sn }}
                      </template>
                    </el-table-column>
                    <el-table-column fixed label="产品编号">
                      <template slot-scope="{ row }">
                        {{ row.productCode }}
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" label="物料编号" min-width="180" class-name="marpno_wuliao">
                      <template slot-scope="{ row }">
                        {{ row.materialCode }}
                      </template>
                    </el-table-column>
                    <el-table-column label="计划预留数量">
                      <template slot-scope="{ row }">
                        {{ row.planReserveMeter + row.purchaseMeasurementUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column label="预留库位">
                      <template slot-scope="{ row }">
                        {{ row.subInventoryName }}
                      </template>
                    </el-table-column>
                    <el-table-column label="期望交期">
                      <template slot-scope="{ row }">
                        {{ row.reserveExpectDate }}
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="预计实际交期">
                      <template slot-scope="{ row }">
                        {{ row.reserveRealDate }}
                      </template>
                    </el-table-column> -->
                    <el-table-column label="在途确认书号">
                      <template slot-scope="{ row }">
                        {{ row.shippmentConfirmNo }}
                      </template>
                    </el-table-column>
                    <el-table-column label="在途">
                      <template slot-scope="{ row }">
                        {{ row.shippmentMeter + row.purchaseMeasurementUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column label="货妥确认书号">
                      <template slot-scope="{ row }">
                        {{ row.producedConfirmNo }}
                      </template>
                    </el-table-column>
                    <el-table-column label="货妥">
                      <template slot-scope="{ row }">
                        {{ row.producedMeter + row.purchaseMeasurementUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column label="在产确认书号">
                      <template slot-scope="{ row }">
                        {{ row.producingConfirmNo }}
                      </template>
                    </el-table-column>
                    <el-table-column label="在产">
                      <template slot-scope="{ row }">
                        {{ row.producingMeter + row.purchaseMeasurementUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column label="询单">
                      <template slot-scope="{ row }">
                        {{ row.requestedMeter + row.purchaseMeasurementUnit }}
                      </template>
                    </el-table-column>
                    <el-table-column label="预留状态">
                      <template slot-scope="{ row }">
                        {{ row.reserveStatusName }}
                      </template>
                    </el-table-column>
                    <el-table-column label="备注">
                      <template slot-scope="{ row }">
                        {{ row.remark }}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right">
                      <template slot-scope="{ row, $index }">
                        <el-button v-if="row.reserveStatus == 'Reserve'" class="el-icon-close" type="primary" circle size="mini" title="解除" @click.stop="removeItemHandle($index, row)"/>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column label="预留单号">
                <template slot-scope="{ row }">
                  {{ row.reserveId }}
                </template>
              </el-table-column>
              <el-table-column label="预留类型">
                <template slot-scope="{ row }">
                  {{ row.reserveTypeName }}
                </template>
              </el-table-column>
              <el-table-column label="业务编号">
                <template slot-scope="{ row }">
                  <span v-if="row.isCustomerOrderType || row.isNotCustomerOrderType">{{ row.saleOrderId }}</span>
                  <span v-else/>
                </template>
              </el-table-column>
              <el-table-column label="客户名称">
                <template slot-scope="{ row }">
                  <span v-if="row.isCustomerOrderType">{{ row.customerName }}</span>
                  <span v-else/>
                </template>
              </el-table-column>
              <el-table-column label="仓位">
                <template slot-scope="{ row }">
                  {{ row.inventoryName }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间">
                <template slot-scope="{ row }">
                  {{ row.createTime }}
                </template>
              </el-table-column>
              <el-table-column label="申请人">
                <template slot-scope="{ row }">
                  {{ row.applicantName }}
                </template>
              </el-table-column>
              <el-table-column label="凭证状态">
                <template slot-scope="{ row, $index }">
                  <el-popover
                    placement="right"
                    width="300"
                    trigger="hover"
                    open-delay="1000"
                    @show="checkApprovalProcess(row, $index)">
                    <div>
                      <i class="block">
                        审批人：
                        <em>{{ row.fullName }}</em>
                      </i>
                      <i class="block mt10 mb10">
                        审批时间：
                        <em>{{ row.approvalTime }}</em>
                      </i>
                      <i class="block">
                        审批意见：
                        <em>{{ row.content }}</em>
                      </i>
                    </div>
                    <el-tag slot="reference" :type="row.auditStatus | statusFilter" class="pointer">{{ row.auditStatusName }}</el-tag>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="{ row, $index }">
                  <div v-if="row.auditStatus == 'Draft' || row.auditStatus == 'Rejected'">
                    <el-button class="el-icon-edit" type="primary" circle size="mini" title="编辑" @click.stop="editHandle($index, row)"/>
                    <el-button class="el-icon-check" type="success" circle size="mini" title="提交审批" @click.stop="submitHandle($index, row)"/>
                    <el-button class="el-icon-delete" type="danger" circle size="mini" title="删除" @click.stop="deleteHandle($index, row)"/>
                  </div>
                  <div v-if="row.auditStatus == 'Approved'">
                    <el-button class="el-icon-edit" type="primary" circle size="mini" title="编辑" @click.stop="editHandle($index, row)"/>
                    <el-button v-if="row.reserveStatus == 'Reserve'" class="el-icon-close" type="primary" circle size="mini" title="整单解除" @click.stop="removeHandle($index, row)"/>
                  </div>
                </template>
              </el-table-column>
            </el-table>

          </el-tab-pane>
        </el-tabs>

        <div class="pagination_wrap fr">
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="[10, 50]"
            :page-size="pageSize"
            :total="total"
            layout="sizes, total, prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <!-- 编辑 -->
    <div v-loading="editPartLoading" v-else class="edit_part">
      <div class="clearfix mb10">
        <div class="display_ib mr10">
          <span class="tag"><span class="red mr5">*</span>预留类型：</span>
          <el-select
            :disabled="!isEmptyData"
            v-model="editData.reserveType"
            class="select_single w200 mr10 mb10"
            placeholder="请选择"
            @change="reserveTypeChange">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in reserveTypeList"
              :key="item.dictItemValue"
              :label="item.dictItemName"
              :value="item.dictItemValue"/>
          </el-select>
        </div>
        <div class="display_ib mr10">
          <span class="tag"><span class="red mr5">*</span>预留仓位：</span>
          <el-select
            :disabled="!isEmptyData"
            v-model="editData.inventory"
            class="select_single w200 mr10 mb10"
            placeholder="请选择"
            @change="inventoryChange">
            <el-option key="" label="请选择" value=""/>
            <el-option
              v-for="item in inventoryTypeList"
              :key="item.index"
              :label="item.name"
              :value="item.index"/>
          </el-select>
        </div>
        <div v-if="isCustomOrder" class="display_ib mr10">
          <span class="tag"><span v-if="isCustomOrderRequired" class="red mr5">*</span>业务编号：</span>
          <el-input :disabled="!isEmptyData" v-model="editData.saleOrderId" class="input_single w200 mr10 mb10" placeholder="请输入"/>
        </div>
        <el-button
          v-if="isCustomOrder"
          :disabled="!isEmptyData"
          class="mr10"
          size="small"
          type="primary"
          icon="el-icon-refresh-right"
          circle
          title="拉取数据"
          @click="pullDataHandle"
        />
        <div v-if="isCustomOrder && editData.customerName" class="display_ib mr10">
          <span class="tag">客户名称：</span>
          <span class="tag">{{ editData.customerName }}</span>
          <!-- <el-input class="input_single w200 mr10 mb10" v-model="editData.customerId" type="hidden" placeholder=""></el-input> -->
        </div>
        <a class="link inline_block lh36 fr ml10" type="primary" @click="exportExcelTemplate">计划预留导入模板</a>
        <el-button
          class="fr"
          size="small"
          type="primary"
          icon="el-icon-plus"
          circle
          title="添加"
          @click="addEditHandle"
        />
      </div>
      <upload-excel-component :on-success="importExcelSuccess" :before-upload="importExcelBeforeUpload" class="mb20" />
      <div class="hidden">
        <!-- 编辑组件 -->
        <editTable
          ref="editTable"
          :table-list="editData.planReserveItemList"
          :total="editData.planReserveItemList && editData.planReserveItemList.length ? editData.planReserveItemList.length : 0"
          :table-leader-fields-list="leaderFieldsList"
          :table-tail-fields-list="tailFieldsList"
          :table-fields-list="editFieldsList"
          @deleteHandle="deleteEditItemHandle"
          @inputChange="inputChange"
          @selectChange="selectChange"
          @selectVisibleChange="selectVisibleChange"
          @datePickerChange="datePickerChange"
          @numberInputChange="numberInputChange"/>

        <div v-if="!isEmptyData" class="edit_btn_wrap mt20 fr">
          <el-button @click="cancelEditHandle">取消</el-button>
          <el-button :disabled="isSaveDisabled" type="primary" @click="saveEditHandle">保存</el-button>
          <el-button :disabled="editItemStatus === 'Approved' || isSubmitDisabled" type="primary" @click="submitEditHandle">提交审批</el-button>
          <!-- <el-button v-if="editItemStatus == 'Draft' || editItemStatus == 'Approved' || isAddEnter" type="primary" :disabled="isSubmitDisabled" @click="submitEditHandle">提交审批</el-button> -->
        </div>

        <div v-else class="t_right mt20">
          <el-button @click="cancelEditHandle">取消</el-button>
          <el-button :disabled="isEmptyData" type="primary">保存</el-button>
          <el-button :disabled="isEmptyData" type="primary">提交审批</el-button>
        </div>
      </div>

    </div>

    <!-- 对话框 -->
    <Dialog v-if="dialogVisible" :dialogDesc="dialogDesc" :dialogVisible="dialogVisible" @dialogConfirmHandle="dialogConfirmHandle" @dialogCancelHandle="dialogCancelHandle"/>
  </div>
</template>

<script>
import {
  getMeasurementUnitCache,
  getMaterialCodeCache,
  wmsSubInventoryListCache,
  dictListCache,
  constDictListCache
} from '@/api/cacheApi.js';
import {
  getUUID,
  getBatchUUID,
  getMeasurementUnitByMC,
  approvalProcessInfo
} from '@/api/publicApi.js';
import {
  planReserveList,
  editPlanReserve,
  deletePlanReserve,
  removePlanReserve,
  removePlanReserveItem,
  submitPlanReserve,
  searchUsername,
  reserveQuantitysList,
  pullToolProduction,
  pullClothProduction
} from '@/api/module/planReserveApi.js';
import { timeFormat, excelDateFormatToDate, getTimeRange, exportExcel, simpleGroupBy } from '@/utils/index.js';
import { statusTypeHandle } from '@/utils/public.js';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import Table from '@/layouts/components/common/Table.vue';
import editTable from '@/layouts/components/common/EditTablePagination.vue';
import Dialog from '@/layouts/components/common/Dialog.vue';
// import editTable from "@/layouts/components/common/EditTableList.vue";
export default {
  name: 'Reserve',
  components: {
    Table,
    editTable,
    UploadExcelComponent,
    Dialog
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        Draft: '',
        Approving: 'warning',
        Approved: 'success',
        Rejected: 'danger',
        Terminated: 'info'
      };
      return statusMap[status];
    }
  },
  props: [],
  data() {
    return {
      // 对话框
      dialogVisible: false, // 对话框是否可见
      dialogDesc: '',
      submitId: '',
      deleteId: '',
      revokeId: '',
      closedId: '',
      revokeCode: '',

      // Table组件
      tableLoading: true,
      parentData: {
        status: '',
        data: [
          {
            name: '创建时间',
            field: 'createTime',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '申请人',
            field: 'applicantName',
            class: 'w4p',
            isShowTit: true
          },
          {
            name: '预留单号',
            field: 'reserveId',
            class: 'w12p',
            isShowTit: true
          },
          {
            name: '预留类型',
            field: 'reserveTypeName',
            class: 'w8p',
            isShowTit: true
          },
          {
            name: '业务编号',
            field: 'saleOrderId',
            class: 'w12p',
            isShowTit: true,
            isFilterField: true
          },
          {
            name: '客户名称',
            field: 'customerName',
            class: 'w8p',
            isShowTit: true,
            isFilterField: true
          },
          {
            name: '仓位',
            field: 'inventoryName',
            class: 'w5p',
            isShowTit: true
          },
          {
            name: '凭证状态',
            field: '',
            class: '',
            isShowTit: false,
            isPopover: true
          },
          {
            name: '',
            field: '',
            class: 'fr mr15',
            isShowTit: false,
            isUpDown: true
          },
          {
            name: '',
            field: '',
            class: 'fr',
            isShowTit: false,
            isAction: true
          }
        ]
      },
      childData: {
        name: 'planReserveItemList',
        data: [
          {
            name: '',
            field: 'productCode',
            class: 'w13p'
          },
          {
            name: '',
            field: 'materialCode',
            class: 'w13p'
          },
          {
            name: '',
            field: 'planReserveMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w13p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'reserveExpectDate',
            class: 'w13p'
          },
          {
            name: '',
            field: 'latestTransferDate',
            class: 'w13p'
          },
          {
            name: '',
            field: 'reserveRealDate',
            class: 'w13p'
          },
          {
            name: '',
            field: 'shippmentMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w13p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'producedMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w13p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'producingMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w13p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'requestedMeter',
            field_unit: 'purchaseMeasurementUnit',
            class: 'w13p',
            isQuantityField: true
          },
          {
            name: '',
            field: 'reserveStatusName',
            class: 'w13p'
          },
          {
            name: '',
            field: 'remark',
            class: 'w13p'
          },
          {
            name: '',
            field: '',
            class: 'w10p fr',
            isAction: true
          }
        ]
      },

      orderStatus: '',

      isShowEdit: false,
      isAddEnter: false,
      isSaveDisabled: false,
      isSubmitDisabled: false,
      isShowList: false,
      isCustomOrder: false,
      isCustomOrderRequired: false,
      isEmptyData: false,
      editType: '',
      dateVal: '',
      activeName: 'All',
      editItemStatus: '',
      reserveOrRemove: '',
      auditStatus: '',
      materialCode: '',
      reserveId: '',
      reserveType: '',
      saleOrderId: '',
      startReserveDate: '',
      endReserveDate: '',
      reserveList: [],
      reserveTypeList: [],
      inventoryTypeList: [],
      subInventoryTypeList: [],
      expands: [],
      editData: {},
      editDataClone: {},
      deleteId: '',
      submitId: '',
      removeId: '',
      removeItemId: '',
      submitParamsData: {},
      planReserveMeterCopy: '',
      reserveStatusInfo: '',
      deleteDialogVisible: false,
      submitDialogVisible: false,
      removeDialogVisible: false,
      removeItemDialogVisible: false,
      editItemData: {
        isAdd: true,
        inventory: '',
        subInventory: '',
        subInventoryTypeList: [],
        materialCode: '',
        materialName: '',
        planAvailableMeter: 0,
        planReserveMeter: 0,
        producedMeter: 0,
        producingMeter: 0,
        reservedQuantity: 0,
        productCode: '',
        productName: '',
        remark: '',
        requestedMeter: 0,
        reserveExpectDate: '',
        reserveId: '',
        reserveRealDate: '',
        reserveStatus: '',
        reserveType: '',
        saleOrderId: '',
        shippmentMeter: 0,
        uuid: '',
        purchaseMeasurementUnit: ''
      },
      total: 0,
      pageSize: 10, // 每页显示条数
      currentPage: 1, // 页数

      // NEW TBALE组件列表
      multipleSelection: [],
      childMultipleSelection: [],
      listLoading: false,
      childListLoading: false,
      selectUuidList: [],
      editPartLoading: false,

      subNavList: [
        {
          label: '全部计划预留请求',
          value: 'All'
        },
        {
          label: '草稿',
          value: 'Draft'
        },
        {
          label: '审批中',
          value: 'Approving'
        },
        {
          label: '已通过',
          value: 'Approved'
        },
        {
          label: '已驳回',
          value: 'Rejected'
        }
        // {
        //   label: '已终止',
        //   value: 'Terminated'
        // }
      ],
      allFieldList: [
        {
          name: '产品编号',
          class: 'w13p'
        },
        {
          name: '物料编号',
          class: 'w13p'
        },
        {
          name: '计划预留数量',
          class: 'w13p'
        },
        {
          name: '期望交期',
          class: 'w13p'
        },
        {
          name: '最晚调拨时间',
          class: 'w13p'
        },
        // {
        //   name: '预计实际交期',
        //   class: 'w13p'
        // },
        {
          name: '在途',
          class: 'w13p'
        },
        {
          name: '货妥',
          class: 'w13p'
        },
        {
          name: '在产',
          class: 'w13p'
        },
        {
          name: '询单',
          class: 'w13p'
        },
        {
          name: '预留状态',
          class: 'w13p'
        },
        {
          name: '备注',
          class: 'w13p'
        },
        {
          name: '',
          class: 'w10p'
        }
      ],
      parentFieldsList: [ // Table父列表表头
        {
          name: '预留单号',
          class: 'w12p'
        },
        {
          name: '预留类型',
          class: 'w8p'
        },
        {
          name: '业务编号',
          class: 'w12p'
        },
        {
          name: '客户名称',
          class: 'w8p'
        },
        {
          name: '仓位',
          class: 'w5p'
        },
        {
          name: '创建时间',
          class: 'w8p'
        },
        {
          name: '申请人',
          class: 'w4p'
        },
        {
          name: '凭证状态',
          class: ''
        }
      ],
      // 编辑组件数据
      editFieldsList: [
        {
          name: '物料编号',
          field: 'materialCode',
          class: 'w10p',
          width: '150',
          isRules: true
        },
        {
          name: '预留库位',
          field: 'subInventoryName',
          class: 'w16p',
          width: '150',
          isEdit: true
        },
        {
          name: '* 计划预留数量',
          field: 'planReserveMeter',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isRules: true,
          isQuantityField: true,
          isEdit: true
        },
        {
          name: '可计划数量',
          field: 'planAvailableMeter',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        },

        {
          name: '* 期望交期',
          field: 'reserveExpectDate',
          class: 'w18p',
          width: '180',
          isRules: true,
          isEdit: true
        },
        // {
        //   name: '预计实际交期',
        //   field: 'reserveRealDate',
        //   class: 'w16p',
        //   width: '180'
        // },
        {
          name: '在途确认书号',
          field: 'shippmentConfirmNo',
          class: 'w13p',
          width: '180',
          isEdit: true
        },
        {
          name: '在途',
          field: 'shippmentMeter',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true,
          isEdit: true
        },
        {
          name: '在途预计到库日期',
          field: 'shippmentToInventoryDate',
          class: 'w18p',
          width: '180',
          isEdit: true
        },
        {
          name: '在途可用',
          field: 'shippmentMeterAvailable',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '货妥确认书号', // 货妥确认书号
          field: 'producedConfirmNo',
          class: 'w13p',
          width: '180',
          isEdit: true
        },
        {
          name: '货妥',
          field: 'producedMeter',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true,
          isEdit: true
        },
        {
          name: '货妥可用',
          field: 'producedMeterAvailable',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '在产确认书号',
          field: 'producingConfirmNo',
          class: 'w13p',
          width: '180',
          isEdit: true
        },
        {
          name: '在产',
          field: 'producingMeter',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true,
          isEdit: true
        },
        {
          name: '在产预计货妥日期',
          field: 'cargoReadyToInventoryDate',
          class: 'w18p',
          width: '180',
          isEdit: true
        },
        {
          name: '在产可用',
          field: 'producingMeterAvailable',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '询单',
          field: 'requestedMeter',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true,
          isEdit: true
        },
        {
          name: '询单可用',
          field: 'requestedMeterAvailable',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w13p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '已预留数量',
          field: 'reservedQuantity',
          field_unit: 'purchaseMeasurementUnit',
          class: 'w16p',
          width: '150',
          isQuantityField: true
        },
        {
          name: '备注',
          field: 'remark',
          class: 'w16p',
          width: '200',
          isEdit: true
        }
      ],
      leaderFieldsList: [
        {
          name: '行号',
          field: 'sn',
          class: 'w16p',
          width: '50',
          fixed: 'left'
        },
        {
          name: '* 产品编号',
          field: 'productCode',
          class: 'w16p',
          width: '180',
          isRules: true,
          isEdit: true,
          fixed: 'left'
        }
      ],
      tailFieldsList: [
        {
          name: '操作',
          field: '',
          class: '',
          width: '100',
          isAction: true,
          hasDelete: true,
          fixed: 'right'
        }
      ],
      // 编辑字段呈现方式集合（默认添加模式）
      editModeSet: {},
      textSet: {
        sn: true,
        materialCode: true,
        planAvailableMeter: true,
        reservedQuantity: true,
        shippmentMeterAvailable: true,
        producedMeterAvailable: true,
        producingMeterAvailable: true,
        requestedMeterAvailable: true
      },
      inputSet: {
        productCode: true,
        shippmentConfirmNo: true,
        producedConfirmNo: true,
        producingConfirmNo: true,
        remark: true,
        inputData: {
          maxlength: '',
          placeholder: ''
        }
      },
      selectSet: {
        subInventoryName: {
          selectListName: 'subInventoryTypeList',
          value: 'subInventory',
          label: 'subInventoryName'
        },
        selectData: {
          selectListName: '',
          value: '',
          label: ''
        }
      },
      numberInputSet: {
        planReserveMeter: true,
        shippmentMeter: true,
        producedMeter: true,
        producingMeter: true,
        requestedMeter: true
      },
      datePickerSet: {
        reserveExpectDate: true,
        reserveRealDate: true,
        shippmentToInventoryDate: true,
        cargoReadyToInventoryDate: true
      },

      latestTime: '',
      childHeaderList: ['行号', '预留单号', '产品编号', '物料编号', '计划预留数量', '预留库位', '期望交期', '在途确认书号', '在途', '货妥确认书号', '货妥', '在产确认书号', '在产', '询单', '预留状态', '备注'],
      childFieldsList: ['sn', 'reserveId', 'productCode', 'materialCode', 'planReserveMeter', 'subInventoryName', 'reserveExpectDate', 'shippmentConfirmNo', 'shippmentMeter', 'producedConfirmNo', 'producedMeter', 'producingConfirmNo', 'producingMeter', 'requestedMeter', 'reserveStatusName', 'remark']

    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  mounted() {
    // this.getPlanReserveList();
    this.fun_date();
    this.getReserveTypeList();
    this.getInventoryTypeList();
    // this.addDate();
  },
  activated() {},
  methods: {
    rowClick(row, event, column) {
      // 单击项
      Array.prototype.remove = function(val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };
      if (this.expands.indexOf(row.id) < 0) {
        this.expands.push(row.id);
      } else {
        this.expands.remove(row.id);
      }
    },
    exportExcelTemplate() {
      // 导出Excel模板
      const headers = ['产品编号', '物料编号', '预留库位', '计划预留数量', '可计划数量', '期望交期', '在途确认书号', '在途', '在途预计到库日期', '在途可用', '货妥确认书号', '货妥', '货妥可用', '在产确认书号', '在产', '在产预计货妥日期', '在产可用', '询单', '询单可用', '已预留数量', '备注'];
      const data = [['1010156', 'V886.601/3974', 'A库(批发库)', '120', '0', '2021-04-10', '', '0', '2021-03-24', '0', '', '10', '0', '0', '', '', '0', '0', '0', '120', '客户期望交期：；预留类型：客订-面料']];
      exportExcel(headers, [], data, '计划预留导入模板', true, 'xlsx');
    },
    // Upload Excel Edit
    importExcelBeforeUpload(file) {
      let isType = false;
      const fileName = file.name;
      const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
      const isXLSX = fileType === 'xlsx';
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message({
          message: '请不要上传大于1M的文件！',
          type: 'error'
        });
        return false;
      }
      if (!isXLSX) {
        this.$message.error('仅支持上传.xlsx后缀文件！');
      }
      if (isXLSX) {
        this.editPartLoading = true;
        isType = true;
      }
      return isType;
    },
    async importExcelSuccess({ results, header }) {
      console.log('导入数据：', results, header);
      if (!this.editData.reserveType) {
        this.$message({
          type: 'error',
          message: '请选择预留类型！'
        });
        this.editPartLoading = false;
        return;
      }
      if (!this.editData.inventory) {
        this.$message({
          type: 'error',
          message: '请选择预留仓位！'
        });
        this.editPartLoading = false;
        return;
      }
      if (this.isCustomOrder && this.isCustomOrderRequired) {
        if (!this.editData.saleOrderId) {
          this.$message({
            type: 'error',
            message: '请输入业务编号！'
          });
          this.editPartLoading = false;
          return;
        }
      }
      if (results && results.length) {
        let editData = {};
        let uuids = [];
        if (this.isEmptyData) {
          // 空数据
          editData = {
            uditOption: '',
            auditPerson: '',
            auditStatus: '',
            auditTime: '',
            customerName: '',
            inventory: '',
            planReserveItemList: [],
            proposer: '',
            reserveId: '',
            reserveType: '',
            saleOrderId: '',
            subInventory: '',
            submitType: '',
            uuid: ''
          };
          editData = Object.assign(editData, this.editData);
          await getUUID().then(res => {
            if (res.code === '0') {
              editData.uuid = res.data;
            }
          });
          this.updateEditModeSet('add', editData);
          this.isEmptyData = false;
        } else {
          editData = this.editData;
          editData.planReserveItemList = [];
        }

        await getBatchUUID(results.length).then(res => {
          if (res.code === '0') {
            uuids = res.data;
          }
        });

        editData.planReserveItemList = await Promise.all(results.map(async(result, index) => {
          const editItemCopy = JSON.parse(JSON.stringify(this.editItemData));
          if (uuids[index]) {
            editItemCopy.uuid = uuids[index];
          } else {
            await getUUID().then(res => {
              if (res.code === '0') {
                editItemCopy.uuid = res.data;
              }
            });
          }
          editItemCopy.planReserveMeter = editItemCopy.shippmentMeter + editItemCopy.producedMeter + editItemCopy.producingMeter + editItemCopy.requestedMeter;
          editItemCopy.subInventoryTypeList = this.subInventoryTypeList;
          this.updateEditModeSet('add', editItemCopy);

          const subInventory = simpleGroupBy('subInventoryName', this.subInventoryTypeList, (key, value) => value.subInventory)[result['预留库位']]; // 预留库位
          editItemCopy.reserveId = result['预留单号'] ? result['预留单号'] : ''; // 预留单号
          editItemCopy.sn = result['行号'] ? result['行号'] : ''; // 行号
          editItemCopy.productCode = result['产品编号'] ? result['产品编号'] : ''; // 产品编号
          editItemCopy.materialCode = result['物料编号'] ? result['物料编号'] : ''; // 物料编号
          editItemCopy.planReserveMeter = result['计划预留数量'] ? result['计划预留数量'] : 0; // 计划预留数量
          editItemCopy.subInventoryName = result['预留库位'] ? result['预留库位'] : ''; // 预留库位
          editItemCopy.subInventory = subInventory || ''; // 预留库位编码
          editItemCopy.reserveExpectDate = !result['期望交期'] || result['期望交期'] == undefined ? '' : (result['期望交期'].toString().includes('-') ? result['期望交期'] : excelDateFormatToDate(result['期望交期']));
          editItemCopy.reserveRealDate = !result['预计实际交期'] || result['预计实际交期'] == undefined ? '' : (result['预计实际交期'].toString().includes('-') ? result['预计实际交期'] : excelDateFormatToDate(result['预计实际交期']));
          editItemCopy.shippmentConfirmNo = result['在途确认书号'] ? result['在途确认书号'] : ''; // 在途确认书号
          editItemCopy.shippmentMeter = result['在途'] ? result['在途'] : ''; // 在途
          editItemCopy.shippmentToInventoryDate = !result['在途预计到库日期'] || result['在途预计到库日期'] == undefined ? '' : (result['在途预计到库日期'].toString().includes('-') ? result['在途预计到库日期'] : excelDateFormatToDate(result['在途预计到库日期'])); // 在途预计到库日期
          editItemCopy.shippmentMeterAvailable = result['在途可用'] ? result['在途可用'] : ''; // 在途可用
          editItemCopy.producedConfirmNo = result['货妥确认书号'] ? result['货妥确认书号'] : ''; // 货妥确认书号
          editItemCopy.producedMeter = result['货妥'] ? result['货妥'] : ''; // 货妥
          editItemCopy.producedMeterAvailable = result['货妥可用'] ? result['货妥可用'] : ''; // 货妥可用
          editItemCopy.producingConfirmNo = result['在产确认书号'] ? result['在产确认书号'] : ''; // 在产确认书号
          editItemCopy.producingMeter = result['在产'] ? result['在产'] : ''; // 在产
          editItemCopy.cargoReadyToInventoryDate = !result['在产预计货妥日期'] || result['在产预计货妥日期'] == undefined ? '' : (result['在产预计货妥日期'].toString().includes('-') ? result['在产预计货妥日期'] : excelDateFormatToDate(result['在产预计货妥日期'])); // 在产预计货妥日期
          editItemCopy.producingMeterAvailable = result['在产可用'] ? result['在产可用'] : ''; // 在产可用
          editItemCopy.requestedMeter = result['询单'] ? result['询单'] : ''; // 询单
          editItemCopy.requestedMeterAvailable = result['询单可用'] ? result['询单可用'] : ''; // 询单可用
          editItemCopy.reserveStatusName = result['预留状态'] ? result['预留状态'] : ''; // 预留状态
          editItemCopy.remark = result['备注'] ? result['备注'] : ''; // 备注
          editItemCopy.purchaseMeasurementUnit = await this.measurementUnitHandle(editItemCopy);
          const data = await this.quantitysHandle(editItemCopy);
          editItemCopy.producingMeterAvailable = data && data.inProductionNumber ? data.inProductionNumber : 0; // 在产可用
          editItemCopy.producedMeterAvailable = data && data.cargoQuantity ? data.cargoQuantity : 0; // 货妥可用
          editItemCopy.shippmentMeterAvailable = data && data.shippmentInventory ? data.shippmentInventory : 0; // 在途可用
          editItemCopy.requestedMeterAvailable = data && data.purchaseOrderItemDraft ? data.purchaseOrderItemDraft : 0; // 询单可用
          editItemCopy.planAvailableMeter = data && data.subsequentStockRuantity ? (data.subsequentStockRuantity < 0 ? 0 : data.subsequentStockRuantity) : 0; // 可计划数量
          return editItemCopy;
        }));
        this.editData = JSON.parse(JSON.stringify(editData));
        this.editPartLoading = false;
        setTimeout(() => {
          this.editPartLoading = false;
        }, 10000);
      }
    },
    async measurementUnitHandle(data) {
      const res = await getMeasurementUnitCache(data.materialCode);
      if (res.code === '0') {
        return res.data;
      } else {
        return '';
      }
    },
    async materialCodeHandle(data) {
      const res = await getMaterialCodeCache(data.productCode);
      if (res.code === '0') {
        return res.data;
      } else {
        return '';
      }
    },
    async quantitysHandle(data) {
      // 数量数据
      if (!this.editData.inventory || !data.materialCode) return;
      const params = {
        inventory: this.editData.inventory,
        materialCode: data.materialCode,
        subInventory: '',
        currentPlanReserveId: data.id
        // subInventory: data.subInventory
      };
      const res = await reserveQuantitysList(params);
      console.log('拉取数量数据：', res);
      if (res.code === '0') {
        return res.data;
      } else {
        return {};
      }
    },

    async getMaterialCodeHandle(item, i) {
      await getMaterialCodeCache(item.productCode).then(res => {
        if (res.code === '0' && res.data) {
          item.materialCode = res.data;
          // 计量单位
          this.getMeasurementUnitHandle(item);
          // 拉取数量数据
          this.getQuantitysDataHandle(item, i);
        } else {
          item.materialCode = '';
        }
      });
    },

    excelDownload() {
      const headerList = [];
      const fieldsList = ['reserveId', 'reserveTypeName', 'saleOrderId', 'customerName', 'inventoryName', 'createTime', 'applicantName', 'auditStatusName'];
      this.parentFieldsList.forEach(hItem => {
        headerList.push(hItem.name);
      });
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.reserveList;
        const data = this.formatJson(fieldsList, list);
        excel.export_json_to_excel({
          header: headerList,
          data,
          filename: '计划预留',
          autoWidth: true,
          bookType: 'xlsx'
        });
      });
    },
    childExcelDownload(item) {
      const headerList = this.childHeaderList;
      const fieldList = this.childFieldsList;
      import('@/vendor/Export2Excel').then(excel => {
        const list = item.planReserveItemList;
        const data = this.formatJson(fieldList, list);
        excel.export_json_to_excel({
          header: headerList,
          data,
          filename: item.reserveId,
          autoWidth: true,
          bookType: 'xlsx'
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    // 固定展示为两周前的时间范围
    fun_date() {
      this.dateVal = getTimeRange(14);
      this.latestTime = this.dateVal[1];
      this.searchHandle();
    },
    checkApprovalProcess(item, index) {
      approvalProcessInfo(item.uuid).then(res => {
        console.log('审批流程：', res);
        if (res.code === '0') {
          const data = res.data[res.data.length - 1];
          if (data == undefined) {
            item.fullName = '';
            item.approvalTime = '';
            item.content = '';
          } else {
            item.fullName = data.fullName;
            item.approvalTime = data.approvalTime;
            item.content = data.content;
          }
          this.$set(this.reserveList, index, item);
          this.$forceUpdate();
        }
      });
    },
    addDate() {
      var nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      if (date.date < 10) {
        date.date = '0' + date.date; // 补齐
      }
      this.latestTime = date.year + '-' + 0 + date.month + '-' + date.date;
    },

    async pullDataHandle() {
      // 拉取数据
      // this.editPartLoading = true;
      const data = {
        auditOption: '',
        auditPerson: '',
        auditStatus: '',
        auditTime: '',
        customerName: '',
        inventory: '',
        planReserveItemList: [],
        proposer: '',
        reserveId: '',
        reserveType: '',
        reserveTypeName: '',
        saleOrderId: '',
        subInventory: '',
        submitType: '',
        uuid: ''
      };
      data.reserveType = this.editData.reserveType;
      data.reserveTypeName = this.editData.reserveTypeName;
      data.inventory = this.editData.inventory;
      data.saleOrderId = this.editData.saleOrderId;
      data.subInventory = this.editData.subInventory;
      if (!data.inventory) {
        this.$message({
          type: 'error',
          message: '请选择预留仓位！'
        });
        return false;
      }
      if (!data.saleOrderId) {
        this.$message({
          type: 'error',
          message: '请输入业务编号！'
        });
        return false;
      }
      await getUUID().then(res => {
        if (res.code === '0') {
          data.uuid = res.data;
        }
      });
      if (data.reserveType.substr(0, 1) == 'A') {
        // 客订
        if (data.inventory == 'D0') {
          this.orderStatus = '';
        }
        // else {
        //   this.orderStatus = 2;
        // }
        searchUsername({ saleOrderId: this.editData.saleOrderId, orderStatus: this.orderStatus }).then(res => {
          if (res.code === '0') {
            if (!res.data) {
              this.$message({
                type: 'error',
                message: `无法拉取销售订单${this.editData.saleOrderId}，请检查${this.editData.saleOrderId}订单状态！`
              });
              return false;
            }
            data.customerName = res.data.customerName;
            this.editData.customerName = res.data.customerName;
            // this.editData.customerId = res.data.customerId;
            if (res.data.saleOrderItemList.length) {
              this.isEmptyData = false;
              for (var i = 0; i < res.data.saleOrderItemList.length; i++) {
                const orderData = res.data.saleOrderItemList[i];
                getUUID().then(res => {
                  if (res.code === '0') {
                    orderData.uuid = res.data;
                  }
                });
                orderData.isAdd = true;
                orderData.planReserveMeter = orderData.shippmentMeter + orderData.producedMeter + orderData.producingMeter + orderData.requestedMeter;
                orderData.subInventoryTypeList = this.subInventoryTypeList;
                // orderData.reserveExpectDate = res.data.orderUpdateTime;
                orderData.reserveExpectDate = orderData.customerOfferedDate;
                orderData.planReserveMeter = orderData.quantity;
                orderData.producingMeter = orderData.producingMeter ? orderData.producingMeter : 0;
                orderData.producedMeter = orderData.producedMeter ? orderData.producedMeter : 0;
                orderData.shippmentMeter = orderData.shippmentMeter ? orderData.shippmentMeter : 0;
                orderData.requestedMeter = orderData.requestedMeter ? orderData.requestedMeter : 0;
                orderData.reservedQuantity = orderData.reservedQuantity ? orderData.reservedQuantity : 0; // 已预留数量
                orderData.planAvailableMeter = orderData.planAvailableMeter ? orderData.planAvailableMeter : 0;
                orderData.remark = `客户期望交期：${orderData.customerOfferedDate}；预留类型：${this.editData.reserveTypeName}`;
                // 拉取数量数据
                this.getQuantitysDataHandle(orderData, i);
                this.updateEditModeSet('edit', orderData);
              }
            }
            data.planReserveItemList = res.data.saleOrderItemList;
            this.editData = data;
          } else {
            data.customerName = '';
            this.editData.customerName = '';
            // this.editData.customerId = '';
          }
          this.editPartLoading = false;
        });
      } else if (data.reserveType.substr(0, 1) == 'B') {
        // 非客订
        // if(data.inventory=='D0'){
        // this.orderStatus='';
        // }else{
        //   this.orderStatus=2;
        // }
        if (!data.reserveTypeName.includes('面料')) {
          pullToolProduction(this.editData.saleOrderId).then(res => {
            // console.log('根据MO拉取销售工具生产单：', res);
            console.log('非客订拉取数据：', res);
            if (res.code === '0') {
              if (!res.data.length) {
                this.$message({
                  type: 'error',
                  message: '此销售订单下无数据！'
                });
                return false;
              }
              this.isEmptyData = false;
              for (var i = 0; i < res.data.length; i++) {
                const orderData = res.data[i];
                orderData.productCode = orderData.sourceProductCode;
                orderData.materialCode = orderData.sourceMaterialCode;
                orderData.planReserveMeter = orderData.sourceQuantity;
                orderData.reserveExpectDate = orderData.requiredDate;
                orderData.subInventoryTypeList = this.subInventoryTypeList;
                orderData.producingMeter = orderData.producingMeter ? orderData.producingMeter : 0;
                orderData.producedMeter = orderData.producedMeter ? orderData.producedMeter : 0;
                orderData.shippmentMeter = orderData.shippmentMeter ? orderData.shippmentMeter : 0;
                orderData.requestedMeter = orderData.requestedMeter ? orderData.requestedMeter : 0;
                orderData.planAvailableMeter = orderData.planAvailableMeter ? orderData.planAvailableMeter : 0;
                orderData.reservedQuantity = orderData.reservedQuantity ? orderData.reservedQuantity : 0; // 已预留数量
                // orderData.remark = `客户期望交期：${ orderData.customerOfferedDate }；预留类型：${ this.editData.reserveTypeName }`;
                // 拉取数量数据
                this.getQuantitysDataHandle(orderData, i);
                this.updateEditModeSet('edit', orderData);
              }
              data.planReserveItemList = res.data;
              this.editData = data;
            }
            this.editPartLoading = false;
          });
        } else {
          this.editPartLoading = false;
        }
      }
    },
    async pullQuantitysDataHandle(data) {
      // 拉取数量数据
      if (!this.editData.inventory || !data.materialCode) {
        data.producingMeterAvailable = 0;
        data.producedMeterAvailable = 0;
        data.shippmentMeterAvailable = 0;
        data.requestedMeterAvailable = 0;
        data.planAvailableMeter = 0;
        // data.producingMeter = 0;
        // data.producedMeter = 0;
        // data.shippmentMeter = 0;
        // data.requestedMeter = 0;
        // data.planAvailableMeter = 0;
        return false;
      }
      const quantitysData = {
        inventory: this.editData.inventory,
        materialCode: data.materialCode,
        subInventory: '',
        currentPlanReserveId: data.id
        // subInventory: data.subInventory
      };
      await reserveQuantitysList(quantitysData).then(res => {
        // console.log('拉取数量数据：', res);
        if (res.code === '0') {
          data.producingMeterAvailable = res.data.inProductionNumber ? res.data.inProductionNumber : 0; // 在产可用
          data.producedMeterAvailable = res.data.cargoQuantity ? res.data.cargoQuantity : 0; // 货妥可用
          data.shippmentMeterAvailable = res.data.shippmentInventory ? res.data.shippmentInventory : 0; // 在途可用
          data.requestedMeterAvailable = res.data.purchaseOrderItemDraft ? res.data.purchaseOrderItemDraft : 0; // 询单可用
          data.planAvailableMeter = res.data.subsequentStockRuantity ? (res.data.subsequentStockRuantity < 0 ? 0 : res.data.subsequentStockRuantity) : 0; // 可计划数量
          // data.producingMeter = res.data.inProductionNumber ? res.data.inProductionNumber : 0; //在产
          // data.producedMeter = res.data.cargoQuantity ? res.data.cargoQuantity : 0; //货妥
          // data.shippmentMeter = res.data.shippmentInventory ? res.data.shippmentInventory : 0; //在途
          // data.requestedMeter = res.data.purchaseOrderItemDraft ? res.data.purchaseOrderItemDraft : 0; //询单
          // data.planAvailableMeter = res.data.subsequentStockRuantity ? (res.data.subsequentStockRuantity < 0 ? 0 : res.data.subsequentStockRuantity) : 0;  // 可计划数量
          this.$forceUpdate();
        }
      });
    },

    async getQuantitysDataHandle(data, index) {
      // 拉取数量数据
      if (!this.editData.inventory || !data.materialCode) {
        data.producingMeterAvailable = 0;
        data.producedMeterAvailable = 0;
        data.shippmentMeterAvailable = 0;
        data.requestedMeterAvailable = 0;
        data.planAvailableMeter = 0;
        return false;
      }
      const params = {
        inventory: this.editData.inventory,
        materialCode: data.materialCode,
        subInventory: '',
        currentPlanReserveId: data.id
        // subInventory: data.subInventory
      };
      await reserveQuantitysList(params).then(res => {
        // console.log('拉取数量数据：', res);
        if (res.code === '0') {
          data.producingMeterAvailable = res.data.inProductionNumber ? res.data.inProductionNumber : 0; // 在产可用
          data.producedMeterAvailable = res.data.cargoQuantity ? res.data.cargoQuantity : 0; // 货妥可用
          data.shippmentMeterAvailable = res.data.shippmentInventory ? res.data.shippmentInventory : 0; // 在途可用
          data.requestedMeterAvailable = res.data.purchaseOrderItemDraft ? res.data.purchaseOrderItemDraft : 0; // 询单可用
          data.planAvailableMeter = res.data.subsequentStockRuantity ? (res.data.subsequentStockRuantity < 0 ? 0 : res.data.subsequentStockRuantity) : 0; // 可计划数量
          this.$set(this.editData.planReserveItemList, index, data);
        }
      });
    },

    reserveTypeChange(val) {
      // 预留类型更新
      // 客订：A... 非客订：B... 其他：C...
      const editDataCopy = JSON.parse(JSON.stringify(this.editData));
      const reserveTypeData = this.reserveTypeList.find(item => {
        return item.dictItemValue == val;
      });
      editDataCopy.reserveTypeName = reserveTypeData.dictItemName;
      this.editData = editDataCopy;

      // this.isCustomOrder = (val.indexOf('A') != -1 ? true : false);  // 客订
      if (val.substr(0, 1) == 'A') {
        // 客订
        this.isCustomOrder = true;
        this.isCustomOrderRequired = true;
      }
      if (val.substr(0, 1) == 'B') {
        // 非客订
        // if (this.editData.reserveTypeName.includes('面料')) {
        //   this.isCustomOrder = false;
        // } else {
        //   this.isCustomOrder = true;
        // }
        this.isCustomOrder = true;
        this.isCustomOrderRequired = false;
      }
      if (val.substr(0, 1) == 'C') {
        // 其他
        this.isCustomOrder = false;
      }
    },
    inventoryChange(val) {
      // 预留仓位更改
      if (this.editData.planReserveItemList && this.editData.planReserveItemList.length) {
        this.editData.planReserveItemList.forEach(item => {
          item.subInventory = '';
        });
      }
      this.getSubInventoryTypeList(val);
    },
    fieldsByReserveType(reserveTypeName) {
      // 根据不同预留类型显示父项字段
      if (reserveTypeName.slice(0, 2) == '客订') {
        this.isShowSaleOrderId = true;
        this.isShowCustomerName = true;
      } else if (reserveTypeName.includes('非客订')) {
        if (reserveTypeName.includes('面料')) {
          // 非客订-面料
          this.isNotRequiredSOI = true;
          this.isDisabledPull = true;
        } else {
          this.isNotRequiredSOI = false;
          this.isDisabledPull = false;
        }
        this.isShowSaleOrderId = true;
        this.isShowCustomerName = false;
      } else {
        // 其他
        this.isNotRequiredSOI = true;
        this.isDisabledPull = true;
        this.isShowSaleOrderId = true;
        this.isShowCustomerName = false;
      }
    },
    addHandle() {
      this.editData = {};
      this.editType = 'add';
      this.isShowEdit = true;
      this.isEmptyData = true;
      this.isCustomOrder = false;
      this.isCustomOrderRequired = false;
      // this.isAddEnter = true;
      this.editItemStatus = '';
      this.isSaveDisabled = false;
      this.isSubmitDisabled = false;
      this.editDataClone = {};
    },
    deleteHandle(index, item) {
      // 删除
      // this.deleteId = item.id;
      // this.deleteDialogVisible = true;

      this.dialogVisible = true;
      this.dialogType = 'delete';
      this.dialogDesc = '确定删除？';
      this.deleteId = item.id;
    },
    dialogCancelHandle() {
      this.dialogVisible = false;
    },
    dialogConfirmHandle() {
      // 对话框确认
      switch (this.dialogType) {
        case 'submit':
          submitPlanReserve(this.submitId).then(res => {
            // console.log('提交审批项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.auditStatus = 'Approving';
              this.getPlanReserveList();
              this.$message({
                type: 'success',
                message: '提交审批成功!'
              });
              this.activeName = 'Approving';
            }
            this.dialogVisible = false;
          });
          break;
        case 'delete':
          deletePlanReserve(this.deleteId).then(res => {
            // console.log('删除项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.getPlanReserveList();
              this.$message({
                type: 'success',
                message: '删除成功！'
              });
            }
            this.dialogVisible = false;
          });
          break;
        case 'remove':
          // 解除
          const data = {
            applicant: this.$store.state.userName,
            applicantName: this.$store.state.realName,
            applicantDepartment: this.$store.state.currentDepartment.id,
            applicantDepartmentName: this.$store.state.currentDepartment.name
          };
          removePlanReserve(this.removeId, data).then(res => {
            // console.log('解除项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.getPlanReserveList();
              this.$message({
                type: 'success',
                message: '解除成功!'
              });
            }
            this.dialogVisible = false;
          });
          break;
        case 'removeItem':
          removePlanReserveItem(this.removeItemId).then(res => {
            // console.log('解除子项：', res);
            if (res.code === '0') {
              this.dialogVisible = false;
              this.getPlanReserveList();
              this.$message({
                type: 'success',
                message: '解除成功!'
              });
            }
            this.dialogVisible = false;
          });
          break;
        default:
          break;
      }
    },
    editHandle(index, item) {
      // 编辑
      console.log('编辑项：', item);
      this.editType = 'edit';
      this.isEmptyData = false;
      this.isShowEdit = true;
      this.isCustomOrder = (item.reserveType.indexOf('A') != -1);
      this.isCustomOrderRequired = (item.reserveType.indexOf('A') == -1);
      item.inventory ? this.getSubInventoryTypeList(item.inventory) : '';
      this.editData = JSON.parse(JSON.stringify(item));
      for (let i = 0; i < this.editData.planReserveItemList.length; i++) {
        const cItem = this.editData.planReserveItemList[i];
        // // 查询库位名称
        // if (cItem.subInventory) {
        //   let subInventoryData = this.subInventoryTypeList.find(siItem => {
        //     return siItem.subInventory == cItem.subInventory;
        //   });
        //   cItem.subInventoryName = subInventoryData ? subInventoryData.subInventoryName : '';
        // }
        // this.pullQuantitysDataHandle(cItem);
        this.getQuantitysDataHandle(cItem, i);
        this.updateEditModeSet('edit', cItem);
        // cItem.planReserveMeter = parseInt(cItem.shippmentMeter) + parseInt(cItem.producedMeter) + parseInt(cItem.producingMeter) + parseInt(cItem.requestedMeter);
        cItem.planReserveMeterCopy = JSON.parse(JSON.stringify(cItem.planReserveMeter));
      }
      this.editDataClone = JSON.parse(JSON.stringify(this.editData));
      this.editItemStatus = item.auditStatus;
      this.$forceUpdate();
    },
    submitHandle(index, item) {
      // 提交
      // this.submitParamsData = {
      //   auditStatus: 'Approving',
      //   auditTime: item.auditTime,
      //   uuid: item.uuid,
      //   reserveId: item.reserveId,
      //   applicant: this.$store.state.userName,
      //   applicantName: this.$store.state.realName,
      //   applicantDepartment: this.$store.state.currentDepartment.id,
      //   applicantDepartmentName: this.$store.state.currentDepartment.name
      // };

      // this.submitId = item.id;
      // this.editData = item;
      // this.submitDialogVisible = true;

      this.dialogVisible = true;
      this.dialogType = 'submit';
      this.dialogDesc = '确定提交审批？';
      this.submitId = item.id;
    },
    removeHandle(index, item) {
      // 解除/预留
      // this.$confirm('确认解除此条信息?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   let data = {
      //     applicant: this.$store.state.userName,
      //     applicantName: this.$store.state.realName,
      //     applicantDepartment: this.$store.state.currentDepartment.id,
      //     applicantDepartmentName: this.$store.state.currentDepartment.name
      //   };
      //   removePlanReserve(item.id, data).then(res => {
      //     // console.log('解除项：', res);
      //     if (res.code === '0') {
      //       this.getPlanReserveList();
      //       this.$message({
      //         type: 'success',
      //         message: '解除成功!'
      //       });
      //     }
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消解除'
      //   });
      // });

      // this.removeId = item.id;
      // this.removeDialogVisible = true;

      this.dialogVisible = true;
      this.dialogType = 'remove';
      this.dialogDesc = '确定解除？';
      this.removeId = item.id;
    },
    removeItemHandle(index, item) {
      // 解除/预留子项
      // console.log('解除子项详情：', index, item);
      // this.$confirm('确认解除此条信息?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   removePlanReserveItem(item.id).then(res => {
      //     // console.log('解除子项：', res);
      //     if (res.code === '0') {
      //       this.getPlanReserveList();
      //       this.$message({
      //         type: 'success',
      //         message: '解除成功!'
      //       });
      //     }
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消解除'
      //   });
      // });

      // this.removeItemId = item.id;
      // this.removeItemDialogVisible = true;

      this.dialogVisible = true;
      this.dialogType = 'removeItem';
      this.dialogDesc = '确定解除？';
      this.removeItemId = item.id;
    },
    async addEditHandle() {
      // 添加编辑项
      console.log('添加编辑项：', this.editData);
      if (!this.editData.reserveType) {
        this.$message({
          type: 'error',
          message: '请选择预留类型！'
        });
        return false;
      }
      if (!this.editData.inventory) {
        this.$message({
          type: 'error',
          message: '请选择预留仓位！'
        });
        return false;
      }
      if (this.isCustomOrder && this.isCustomOrderRequired) {
        if (!this.editData.saleOrderId) {
          this.$message({
            type: 'error',
            message: '请输入业务编号！'
          });
          return false;
        }
      }
      this.$nextTick(() => {
        this.$refs.editTable.targetingLastPageHandle();
      });
      if (this.isEmptyData) {
        const data = {
          auditOption: '',
          auditPerson: '',
          auditStatus: '',
          auditTime: '',
          customerName: '',
          inventory: '',
          planReserveItemList: [],
          proposer: '',
          reserveId: '',
          reserveType: '',
          saleOrderId: '',
          subInventory: '',
          submitType: '',
          uuid: ''
        };
        const editItemCopy = JSON.parse(JSON.stringify(this.editItemData));
        data.reserveType = this.editData.reserveType;
        data.inventory = this.editData.inventory;
        this.editData.saleOrderId ? data.saleOrderId = this.editData.saleOrderId : data.saleOrderId = '';
        editItemCopy.planReserveMeter = editItemCopy.shippmentMeter + editItemCopy.producedMeter + editItemCopy.producingMeter + editItemCopy.requestedMeter;
        editItemCopy.subInventoryTypeList = this.subInventoryTypeList;
        this.updateEditModeSet('add', editItemCopy);
        await getUUID().then(res => {
          if (res.code === '0') {
            data.uuid = res.data;
          }
        });
        await getUUID().then(res => {
          if (res.code === '0') {
            editItemCopy.uuid = res.data;
            data.planReserveItemList.push(editItemCopy);
            this.isEmptyData = false;
          }
        });
        this.editData = data;
      } else {
        const editDataCopy = JSON.parse(JSON.stringify(this.editData));
        const editItemCopy = JSON.parse(JSON.stringify(this.editItemData));
        editItemCopy.planReserveMeter = editItemCopy.shippmentMeter + editItemCopy.producedMeter + editItemCopy.producingMeter + editItemCopy.requestedMeter;
        editItemCopy.subInventoryTypeList = this.subInventoryTypeList;
        this.updateEditModeSet('add', editItemCopy);
        await getUUID().then(res => {
          if (res.code === '0') {
            editItemCopy.uuid = res.data;
            editDataCopy.planReserveItemList.push(editItemCopy);
          }
        });
        this.editData = editDataCopy;
      }
    },
    deleteEditItemHandle(index, item) {
      // 删除项
      const editDataCopy = JSON.parse(JSON.stringify(this.editData));
      editDataCopy.planReserveItemList.splice(index, 1);
      this.editData = editDataCopy;
    },
    cancelEditHandle() {
      // 取消
      this.isShowEdit = false;
      this.isAddEnter = false;
      this.editItemStatus = '';
    },
    async wholeQueryHandle(list) {
      const temp = {};
      for (let i = 0; i < list.length; i++) {
        const key = list[i].materialCode;
        if (temp[key]) {
          temp[key].materialCode = temp[key].materialCode;
          temp[key].planReserveMeterTotal += parseFloat(list[i].planReserveMeter.toFixed(2));
        } else {
          temp[key] = {};
          temp[key].materialCode = key;
          temp[key].planReserveMeterTotal = parseFloat(list[i].planReserveMeter.toFixed(2));
        }
      }
      list.forEach(item => {
        for (const i in temp) {
          if (item.materialCode == i) {
            item.planReserveMeterTotal = temp[i].planReserveMeterTotal;
          }
        }
      });
    },
    saveEditHandle() {
      // 保存
      console.log('保存数据：', this.editData);
      const list = this.editData.planReserveItemList;
      const planReserveMeterTotal = 0;
      if (this.editData.actions) {
        delete this.editData.actions;
      }
      if (this.editData.isHideFew) {
        delete this.editData.isHideFew;
      }
      if (!list.length) {
        this.$message({
          type: 'error',
          message: '请添加数据！'
        });
        return false;
      }
      if (!this.editData.reserveType) {
        this.$message({
          type: 'error',
          message: '请选择预留类型！'
        });
        return false;
      }
      if (!this.editData.inventory) {
        this.$message({
          type: 'error',
          message: '请选择预留仓位！'
        });
        return false;
      }
      if (this.isCustomOrder && this.isCustomOrderRequired) {
        if (!this.editData.saleOrderId) {
          this.$message({
            type: 'error',
            message: '请输入业务编号！'
          });
          return false;
        }
      }
      this.wholeQueryHandle(list);
      for (var i = 0; i < list.length; i++) {
        const data = list[i];
        data.planReserveMeterTotal = parseFloat(data.planReserveMeterTotal.toFixed(2));
        if (!data.productCode) {
          this.$message({
            type: 'error',
            message: '请输入产品编号！'
          });
          return false;
        }
        if (!data.materialCode) {
          this.$message({
            type: 'error',
            message: '查无此产品编号！'
          });
          return false;
        }
        if (!data.planReserveMeter) {
          this.$message({
            type: 'error',
            message: '请输入计划预留数量！'
          });
          return false;
        }
        if (!this.verificationHandle(data.planReserveMeter, '计划预留数量')) {
          data.planReserveMeter = 0;
          return false;
        }
        if (!data.reserveExpectDate) {
          this.$message({
            type: 'error',
            message: '请输入期望交期！'
          });
          return false;
        }
        if (!this.verificationHandle(data.planAvailableMeter, '可计划数量')) {
          data.planAvailableMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.shippmentMeter, '在途数量')) {
          data.shippmentMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.producedMeter, '货妥数量')) {
          data.producedMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.producingMeter, '在产数量')) {
          data.producingMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.requestedMeter, '询单数量')) {
          data.requestedMeter = 0;
          return false;
        }
        if (data.planReserveMeter != parseFloat((Number(data.shippmentMeter) + Number(data.producedMeter) + Number(data.producingMeter) + Number(data.requestedMeter) + Number(data.reservedQuantity)).toFixed(2))) {
          this.$message({
            type: 'error',
            message: '计划预留数量应等于在途、货妥、在产、询单、已预留数量总和！'
          });
          return false;
        }
        if (data.planReserveMeter > data.planAvailableMeter) {
          this.$message({
            type: 'error',
            message: '计划预留数量不能大于可计划数量！'
          });
          return false;
        }
        if (parseFloat(data.planReserveMeterTotal.toFixed(2)) > parseFloat(data.planAvailableMeter.toFixed(2))) {
          this.$message({
            type: 'error',
            message: '相同物料编号下计划预留数量总和不能大于可计划数量！'
          });
          return false;
        }
        /* if (this.editData.auditStatus === 'Approved' && data.planReserveMeter != data.planReserveMeterCopy) {
          this.$message({
            type: 'error',
            message: '计划预留数量已改变，请提交审批！'
          });
          return false;
        }*/
        // 删除编辑字段呈现方式集合
        if (data.editModeSet) {
          delete data.editModeSet;
        }
      }
      this.editData.submitType = '1';
      this.editData.applicant = this.$store.state.userName;
      this.editData.applicantName = this.$store.state.realName;
      this.editData.applicantDepartment = this.$store.state.currentDepartment.id;
      this.editData.applicantDepartmentName = this.$store.state.currentDepartment.name;
      this.isSaveDisabled = true;
      editPlanReserve(this.editData).then(res => {
        // console.log('保存计划预留：', res);
        if (res.code === '0') {
          this.getPlanReserveList();
          this.$message({
            type: 'success',
            message: '保存成功！'
          });
          this.isShowEdit = false;
        }
        this.isSaveDisabled = false;
      });
    },
    submitEditHandle(type) {
      // 提交审批
      // console.log('提交审批：', this.editData);
      const list = this.editData.planReserveItemList;
      if (this.editData.actions) {
        delete this.editData.actions;
      }
      if (this.editData.isHideFew) {
        delete this.editData.isHideFew;
      }
      if (!list.length) {
        this.$message({
          type: 'error',
          message: '请添加数据！'
        });
        return false;
      }
      if (!this.editData.reserveType) {
        this.$message({
          type: 'error',
          message: '请选择预留类型！'
        });
        return false;
      }
      if (!this.editData.inventory) {
        this.$message({
          type: 'error',
          message: '请选择预留仓位！'
        });
        return false;
      }
      if (this.isCustomOrder && this.isCustomOrderRequired) {
        if (!this.editData.saleOrderId) {
          this.$message({
            type: 'error',
            message: '请输入业务编号！'
          });
          return false;
        }
      }
      this.wholeQueryHandle(list);
      for (var i = 0; i < list.length; i++) {
        const data = list[i];
        data.planReserveMeterTotal = parseFloat(data.planReserveMeterTotal.toFixed(2));
        if (!data.productCode) {
          this.$message({
            type: 'error',
            message: '请输入产品编号！'
          });
          return false;
        }
        if (!data.materialCode) {
          this.$message({
            type: 'error',
            message: '查无此产品编号！'
          });
          return false;
        }
        if (!data.planReserveMeter) {
          this.$message({
            type: 'error',
            message: '请输入计划预留数量！'
          });
          return false;
        }
        if (!this.verificationHandle(data.planReserveMeter, '计划预留数量')) {
          data.planReserveMeter = 0;
          return false;
        }
        if (!data.reserveExpectDate) {
          this.$message({
            type: 'error',
            message: '请输入期望交期！'
          });
          return false;
        }
        if (!this.verificationHandle(data.planAvailableMeter, '可计划数量')) {
          data.planAvailableMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.shippmentMeter, '在途数量')) {
          data.shippmentMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.producedMeter, '货妥数量')) {
          data.producedMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.producingMeter, '在产数量')) {
          data.producingMeter = 0;
          return false;
        }
        if (!this.verificationHandle(data.requestedMeter, '询单数量')) {
          data.requestedMeter = 0;
          return false;
        }
        if (data.planReserveMeter != parseFloat((Number(data.shippmentMeter) + Number(data.producedMeter) + Number(data.producingMeter) + Number(data.requestedMeter) + Number(data.reservedQuantity)).toFixed(2))) {
          this.$message({
            type: 'error',
            message: '计划预留数量应等于在途、货妥、在产、询单、已预留数量总和！'
          });
          return false;
        }
        if (data.planReserveMeter > data.planAvailableMeter) {
          this.$message({
            type: 'error',
            message: '计划预留数量不能大于可计划数量！'
          });
          return false;
        }
        if (parseFloat(data.planReserveMeterTotal.toFixed(2)) > parseFloat(data.planAvailableMeter.toFixed(2))) {
          this.$message({
            type: 'error',
            message: '相同物料编号下计划预留数量总和不能大于可计划数量！'
          });
          return false;
        }
        // 删除编辑字段呈现方式集合
        if (data.editModeSet) {
          delete data.editModeSet;
        }
      }
      this.editData.submitType = '2';
      if (this.editData.status == 'Draft') {
        this.editData.auditStatus = 'Draft';
      } else if (this.editData.status == 'Rejected') {
        this.editData.auditStatus = 'Rejected';
      }
      this.editData.applicant = this.$store.state.userName;
      this.editData.applicantName = this.$store.state.realName;
      this.editData.applicantDepartment = this.$store.state.currentDepartment.id;
      this.editData.applicantDepartmentName = this.$store.state.currentDepartment.name;
      this.isSubmitDisabled = true;
      editPlanReserve(this.editData).then(res => {
        // console.log('提交计划预留：', res);
        if (res.code === '0') {
          this.auditStatus = 'Approving';
          this.getPlanReserveList();
          this.$message({
            type: 'success',
            message: '提交审批成功！'
          });
          if (type === 'lineSubmit') {
            this.submitDialogVisible = false;
          }
          this.isShowEdit = false;
          this.activeName = 'Approving';
        }
        this.isSubmitDisabled = false;
      });
    },
    verificationHandle(val, name) {
      // 数量值校验
      if (val && val.toString().indexOf('.') !== -1) {
        const arr = val.toString().split('.');
        if (arr[0].length > 8) {
          this.$message({
            message: `${name}仅支持整数位8位，请重新输入！`,
            type: 'error'
          });
          return false;
        }
        if (arr[1].length > 2) {
          this.$message({
            message: `${name}仅支持小数位2位，请重新输入！`,
            type: 'error'
          });
          return false;
        }
      } else if (val && val.toString().length > 8) {
        this.$message({
          message: `${name}仅支持整数位8位，请重新输入！`,
          type: 'error'
        });
        return false;
      }
      return true;
    },
    switchTabHandle(tab) {
      // tab切换
      // this.tableLoading = true;
      this.currentPage = 1;
      this.reserveList = [];
      switch (tab.name) {
        case 'All':
          // 全部
          this.auditStatus = '';
          break;
        case 'Draft':
          // 草稿
          this.auditStatus = 'Draft';
          break;
        case 'Approving':
          // 审批中
          this.auditStatus = 'Approving';
          break;
        case 'Approved':
          // 已通过
          this.auditStatus = 'Approved';
          break;
        case 'Rejected':
          // 已驳回
          this.auditStatus = 'Rejected';
          break;
        case 'Terminated':
          // 已终止
          this.auditStatus = 'Terminated';
          break;
        default:
          this.auditStatus = '';
          break;
      }
      this.getPlanReserveList();
    },
    showChildList(index, item) {
      item.isShowList = !item.isShowList;
      this.$forceUpdate();
    },
    dateValChange(val) {
      this.searchHandle();
    },
    searchHandle() {
      // 搜索
      this.reserveList = [];
      this.currentPage = 1;
      if (this.dateVal) {
        this.startReserveDate = this.dateVal[0];
        this.endReserveDate = this.dateVal[1];
      } else {
        this.startReserveDate = '';
        this.endReserveDate = '';
      }
      this.currentPage = 1;
      this.getPlanReserveList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPlanReserveList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPlanReserveList();
    },
    getPlanReserveList() {
      // 计划预留列表
      this.tableLoading = true;
      const data = {
        auditStatus: this.auditStatus,
        materialCode: this.materialCode,
        reserveId: this.reserveId,
        reserveType: this.reserveType,
        saleOrderId: this.saleOrderId,
        startReserveDate: this.startReserveDate,
        endReserveDate: this.endReserveDate,
        planTransferDate: this.latestTime
      };
      planReserveList(this.currentPage, this.pageSize, data).then(res => {
        console.log('计划预留列表：', res);
        if (res.code === '0' && res.data.records.length) {
          this.reserveList = res.data.records;
          this.total = res.data.total;
          const list = this.reserveList;
          let childData = null;
          for (var i = 0; i < list.length; i++) {
            list[i].createTime = list[i].createTime ? timeFormat(list[i].createTime) : '';
            list[i].status = list[i].auditStatus;
            list[i].auditStatusName = statusTypeHandle(list[i].auditStatus);

            for (var j = 0; j < list[i].planReserveItemList.length; j++) {
              if (list[i].planReserveItemList[j].reserveStatus == 'Release') {
                this.reserveStatusInfo = false;
              } else {
                this.reserveStatusInfo = true;
              }
            }

            // switch (list[i].auditStatus) {
            //   case 'Draft':
            //     list[i].actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   case 'Approved':
            //     list[i].actions = {
            //       hasEdit: true,
            //       hasRemove: this.reserveStatusInfo
            //     };
            //     break;
            //   case 'Rejected':
            //     list[i].actions = {
            //       hasEdit: true,
            //       hasSubmit: true,
            //       hasDelete: true
            //     };
            //     break;
            //   default:
            //     list[i].actions = {};
            //     break;
            // }

            // 查询仓位名称
            this.inventoryTypeList.forEach(item => {
              if (list[i].inventory == item.index) {
                list[i].inventoryName = item.name;
              }
            });
            this.reserveTypeList.forEach(item => {
              if (list[i].reserveType == item.dictItemValue) {
                list[i].reserveTypeName = item.dictItemName;
              }
            });
            // list[i].isReserveNoCustom = (list[i].reserveType.indexOf('B') != -1 ? true : false);
            // if (list[i].isReserveNoCustom) {
            //   list[i].isHideFew = true;
            // }

            // if (list[i].reserveTypeName && list[i].reserveTypeName.split('-')[0] == '客订') {
            //   // 客订显示客户名称
            //   list[i].hide_customerName = false;
            //   // list[i].isHideFew = true;
            // } else {
            //   list[i].hide_customerName = true;
            // }
            // if (list[i].reserveTypeName && list[i].reserveTypeName.split('-')[0] == '客订' || list[i].reserveTypeName.split('-')[0] == '非客订') {
            //   // 客订、非客订显示业务编号
            //   list[i].hide_saleOrderId = false;
            //   // list[i].isHideFew = true;
            // } else {
            //   list[i].hide_saleOrderId = true;
            // }

            if (list[i].reserveTypeName && list[i].reserveTypeName.split('-')[0] == '客订') {
              // 客订显示客户名称
              list[i].isCustomerOrderType = true;
            } else {
              list[i].isCustomerOrderType = false;
            }
            if (list[i].reserveTypeName && list[i].reserveTypeName.split('-')[0] == '非客订') {
              // 客订、非客订显示业务编号
              list[i].isNotCustomerOrderType = true;
            } else {
              list[i].isNotCustomerOrderType = false;
            }

            for (var j = 0; j < list[i].planReserveItemList.length; j++) {
              childData = list[i].planReserveItemList[j];
              childData.reserveExpectDate ? (childData.reserveExpectDate = timeFormat(childData.reserveExpectDate)) : '';
              childData.reserveRealDate ? (childData.reserveRealDate = timeFormat(childData.reserveRealDate)) : '';
              childData.auditStatus = list[i].auditStatus;
              if (list[i].auditStatus == 'Approved') {
                childData.reserveStatus === 'Release' ? childData.reserveStatusName = '解除' : childData.reserveStatusName = '预留';
              } else {
                childData.reserveStatusName = '';
              }

              // if (childData.reserveStatus == 'Release') {
              //   list[i].reserveStatusInfo = false;
              // } else {
              //   list[i].reserveStatusInfo = true;
              // }

              this.updateEditModeSet('edit', childData, list[i].auditStatus);
            }
          }
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 10000);
    },
    async getReserveTypeList() {
      // 预留类型
      // dictListCache('ReservedType').then(res => {
      //   console.log('预留类型：', res);
      //   if (res.code === '0') {
      //     this.reserveTypeList = res.data;
      //   }
      // });
      let res = await dictListCache('ReservedType')
      console.log('预留类型：', res);
      this.reserveTypeList = res.data;
    },
    async getInventoryTypeList() {
      // 仓位类型
      // constDictListCache('VirtualInventory').then(res => {
      //   console.log('仓位：', res);
      //   if (res.code === '0') {
      //     this.inventoryTypeList = res.data;
      //   }
      // });
      let res = await constDictListCache('VirtualInventory')
      console.log('仓位：', res)
      this.inventoryTypeList = res.data;
    },
    async getSubInventoryTypeList(virtualInventoryId) {
      // 库位列表
      await wmsSubInventoryListCache(virtualInventoryId).then(res => {
        console.log('预留库位列表：', res);
        if (res.code === '0') {
          this.subInventoryTypeList = res.data;
          this.$forceUpdate();
        }
      });
    },
    getMeasurementUnitHandle(item) {
      // 计量单位查询
      getMeasurementUnitCache(item.materialCode).then(res => {
        if (res.code === '0') {
          item.purchaseMeasurementUnit = res.data;
          this.$forceUpdate();
        }
      });
    },
    // 编辑组件函数
    updateEditModeSet(type, data, status) {
      // 编辑字段呈现方式集合
      const editModeSet = JSON.parse(JSON.stringify(this.editModeSet));
      const textSet = JSON.parse(JSON.stringify(this.textSet));
      const inputSet = JSON.parse(JSON.stringify(this.inputSet));
      const selectSet = JSON.parse(JSON.stringify(this.selectSet));
      const numberInputSet = JSON.parse(JSON.stringify(this.numberInputSet));
      const datePickerSet = JSON.parse(JSON.stringify(this.datePickerSet));
      if (type == 'edit') {
        switch (status) {
          case 'Draft':
            // 产品编号
            inputSet.productCode = true;
            textSet.productCode = false;
            // 计划预留数量
            numberInputSet.planReserveMeter = true;
            textSet.planReserveMeter = false;
            // 期望交期
            datePickerSet.reserveExpectDate = true;
            textSet.reserveExpectDate = false;
            break;
          case 'Approved':
            // 产品编号
            inputSet.productCode = false;
            textSet.productCode = true;
            // 计划预留数量
            numberInputSet.planReserveMeter = false;
            textSet.planReserveMeter = true;
            // 期望交期
            datePickerSet.reserveExpectDate = false;
            textSet.reserveExpectDate = true;
            break;
          default:
            break;
        }
      }
      editModeSet.textSet = textSet;
      editModeSet.inputSet = inputSet;
      editModeSet.selectSet = selectSet;
      editModeSet.numberInputSet = numberInputSet;
      editModeSet.datePickerSet = datePickerSet;
      data.editModeSet = editModeSet;
    },
    async inputChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case 'productCode':
          // 产品编号
          // console.log('产品编号值改变：', val, item, fieldItem);
          // 获取物料编号
          await getMaterialCodeCache(val).then(res => {
            if (res.code === '0' && res.data) {
              item.materialCode = res.data;
              // 计量单位
              this.getMeasurementUnitHandle(item);
            } else {
              item.materialCode = '';
            }
          });
          // await this.getMaterialCodeHandle(item);
          this.pullQuantitysDataHandle(item);
          break;

        default:
          break;
      }
    },
    async selectChange(val, item, fieldItem) {
      const editListCopy = JSON.parse(JSON.stringify(this.editData.planReserveItemList));
      switch (fieldItem.field) {
        case 'subInventoryName':
          // 预留库位
          console.log('预留库位值改变：', val, item, fieldItem);
          const subInventoryData = this.subInventoryTypeList.find(siItem => {
            return siItem.subInventory === val;
          });
          item.subInventory = subInventoryData ? subInventoryData.subInventory : '';
          item.subInventoryName = subInventoryData ? subInventoryData.subInventoryName : '';
          // 拉取数量数据
          this.pullQuantitysDataHandle(item);
          break;

        default:
          break;
      }
    },
    selectVisibleChange(val, item, fieldItem) {
      const editListCopy = JSON.parse(JSON.stringify(this.editData.planReserveItemList));
      switch (fieldItem.field) {
        case 'subInventoryName':
          // 预留库位
          console.log('预留库位值改变：', val, item, fieldItem);
          if (!item.subInventoryTypeList || !item.subInventoryTypeList.length) {
            for (var i = 0; i < editListCopy.length; i++) {
              const data = editListCopy[i];
              if (data.uuid === item.uuid) {
                data.subInventoryTypeList = this.subInventoryTypeList;
              }
            }
            this.editData.planReserveItemList = editListCopy;
            this.$forceUpdate();
          }
          break;

        default:
          break;
      }
    },
    numberInputChange(val, item, fieldItem) {
      const editListCopy = JSON.parse(JSON.stringify(this.editData.planReserveItemList));
      const list = this.editDataClone.planReserveItemList;
      switch (fieldItem.field) {
        case 'planReserveMeter':
          // 计划预留数量
          console.log('计划预留数量值改变：', val, item, fieldItem);
          if (!val) {
            item.planReserveMeter = 0;
          }
          if (this.editDataClone.auditStatus == 'Approved') {
            this.isSubmitDisabled = true;
            for (var i = 0; i < list.length; i++) {
              if (list[i].uuid == item.uuid) {
                if (list[i].planReserveMeter == item.planReserveMeter) {
                  this.isSubmitDisabled = false;
                }
              }
            }
          }
          break;
        case 'shippmentMeter':
          // 在途
          console.log('在途值改变：', val, item, fieldItem);
          if (!val) {
            item.shippmentMeter = 0;
          }
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          if (this.editDataClone.auditStatus == 'Approved') {
            this.isSubmitDisabled = true;
            for (var i = 0; i < list.length; i++) {
              if (list[i].uuid == item.uuid) {
                if (list[i].shippmentMeter == item.shippmentMeter) {
                  this.isSubmitDisabled = false;
                }
              }
            }
          }
          break;
        case 'producedMeter':
          // 货妥
          console.log('货妥值改变：', val, item, fieldItem);
          if (!val) {
            item.producedMeter = 0;
          }
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          if (this.editDataClone.auditStatus == 'Approved') {
            this.isSubmitDisabled = true;
            for (var i = 0; i < list.length; i++) {
              if (list[i].uuid == item.uuid) {
                if (list[i].producedMeter == item.producedMeter) {
                  this.isSubmitDisabled = false;
                }
              }
            }
          }
          break;
        case 'producingMeter':
          // 在产
          console.log('在产值改变：', val, item, fieldItem);
          if (!val) {
            item.producingMeter = 0;
          }
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          if (this.editDataClone.auditStatus == 'Approved') {
            this.isSubmitDisabled = true;
            for (var i = 0; i < list.length; i++) {
              if (list[i].uuid == item.uuid) {
                if (list[i].producingMeter == item.producingMeter) {
                  this.isSubmitDisabled = false;
                }
              }
            }
          }
          break;
        case 'requestedMeter':
          // 询单
          console.log('询单值改变：', val, item, fieldItem);
          if (!val) {
            item.requestedMeter = 0;
          }
          // if (this.editItemStatus == 'Draft' || item.isAdd) {
          //   item.planReserveMeter = item.shippmentMeter + item.producedMeter + item.producingMeter + item.requestedMeter;
          // }
          if (this.editDataClone.auditStatus == 'Approved') {
            this.isSubmitDisabled = true;
            for (var i = 0; i < list.length; i++) {
              if (list[i].uuid == item.uuid) {
                if (list[i].requestedMeter == item.requestedMeter) {
                  this.isSubmitDisabled = false;
                }
              }
            }
          }
          break;

        default:
          break;
      }
    },
    datePickerChange(val, item, fieldItem) {
      switch (fieldItem.field) {
        case 'reserveExpectDate':
          console.log('期望交期值改变：', val, item, fieldItem);
          break;
        case 'reserveRealDate':
          console.log('预计实际交期值改变：', val, item, fieldItem);
          // let editDataCopy = JSON.parse(JSON.stringify(this.editData));
          // for (var i = 0; i < editDataCopy.planReserveItemList.length; i++) {
          //   let data = editDataCopy.planReserveItemList[i];
          //   if (data.uuid == item.uuid) {
          //     data.reserveRealDate = val;
          //   }
          // }
          // this.editData = editDataCopy;
          item.reserveRealDate = val;
          this.$forceUpdate();
          break;

        default:
          break;
      }
    }

  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
* {
  box-sizing: border-box;
}

.reserve {
  padding: 10px 15px;
  .actions_part {
    margin: 10px 0;
  }
  .edit_wrap {
    overflow: auto;
    .childlist {
      width: 100%;
    }
    .edit_parent_list {
      width: 100%;
      .edit_item {
        // height: 120px;
        width: 100%;
        height: 100%;
        position: relative;
        border-bottom: 1px solid #EEEEEE;
        li {
          line-height: 32px;
          span {
            vertical-align: middle;
          }
        }
      }
      .add_btn {
        position: absolute;
        bottom: 14px;
        right: 60px;
      }
      ul {
        &.childcontentlist {
          border-bottom: none;
        }
      }
      .edit_btn_wrap {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .meters {
      width: 150px;
      height: 32px;
      line-height: 32px;
      text-align: left;
      vertical-align: top;
      .count {
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.table_part {
  position: relative;
  ul {
    li {
      outline: none;
    }
    &.childcontentlist {
      height: 60px;
      line-height: 40px;
    }
    &.childlist {
      li {
        cursor: pointer;
      }
    }
  }
  .submit_btn {
    position: absolute;
    top: -60px;
    right: 0;
  }
}

.pagination_wrap {
  margin-top: 20px;
}
</style>
<style>
/* popover弹出框 */
.popover_table.el-table .el-table__header th,
.popover_table.el-table .el-table__header tr {
  height: 40px;
  background-color: #f5f7fa;
}
.el-date-editor.el-range-editor.el-input__inner.mr10.mb10.el-date-editor--daterange {
  width: 320px;
}
</style>
