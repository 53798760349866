var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container reserve" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("预留单号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.reserveId,
                        callback: function($$v) {
                          _vm.reserveId = $$v
                        },
                        expression: "reserveId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("业务编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.saleOrderId,
                        callback: function($$v) {
                          _vm.saleOrderId = $$v
                        },
                        expression: "saleOrderId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode = $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("预留类型：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.reserveType,
                          callback: function($$v) {
                            _vm.reserveType = $$v
                          },
                          expression: "reserveType"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.reserveTypeList, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              label: item.dictItemName,
                              value: item.dictItemValue
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("期望交期：")]),
                    _c("el-date-picker", {
                      staticClass: "mr10",
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("计划调拨时间：")
                    ]),
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期"
                      },
                      model: {
                        value: _vm.latestTime,
                        callback: function($$v) {
                          _vm.latestTime = $$v
                        },
                        expression: "latestTime"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.excelDownload }
                    },
                    [_vm._v("导出Excel")]
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.listLoading,
                                expression: "listLoading"
                              }
                            ],
                            ref: "multipleTable",
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.reserveList,
                              "expand-row-keys": _vm.expands,
                              "row-key": "id",
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actions_wrap mb20 clearfix"
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出Excel")]
                                            ),
                                            scope.row.auditStatus == "Draft" ||
                                            scope.row.auditStatus == "Rejected"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "fr mr10",
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editHandle(
                                                          scope.$index,
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("更新导入Excel")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-table",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.childListLoading,
                                                expression: "childListLoading"
                                              }
                                            ],
                                            ref:
                                              "childMultipleTable_" +
                                              scope.row.id,
                                            refInFor: true,
                                            staticClass: "table_list",
                                            attrs: {
                                              data:
                                                scope.row.planReserveItemList,
                                              border: "",
                                              fit: ""
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                fixed: "",
                                                label: "行号"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(row.sn) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                fixed: "",
                                                label: "产品编号"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productCode
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                "show-overflow-tooltip": true,
                                                label: "物料编号",
                                                "min-width": "180",
                                                "class-name": "marpno_wuliao"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.materialCode
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "计划预留数量" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.planReserveMeter +
                                                                row.purchaseMeasurementUnit
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "预留库位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.subInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "期望交期" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.reserveExpectDate
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "在途确认书号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.shippmentConfirmNo
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "在途" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.shippmentMeter +
                                                                row.purchaseMeasurementUnit
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "货妥确认书号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.producedConfirmNo
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "货妥" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.producedMeter +
                                                                row.purchaseMeasurementUnit
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "在产确认书号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.producingConfirmNo
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "在产" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.producingMeter +
                                                                row.purchaseMeasurementUnit
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "询单" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.requestedMeter +
                                                                row.purchaseMeasurementUnit
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "预留状态" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.reserveStatusName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "备注" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(row.remark) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "操作",
                                                fixed: "right"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      var $index = ref.$index
                                                      return [
                                                        row.reserveStatus ==
                                                        "Reserve"
                                                          ? _c("el-button", {
                                                              staticClass:
                                                                "el-icon-close",
                                                              attrs: {
                                                                type: "primary",
                                                                circle: "",
                                                                size: "mini",
                                                                title: "解除"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.removeItemHandle(
                                                                    $index,
                                                                    row
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "预留单号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.reserveId) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "预留类型" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.reserveTypeName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "业务编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        row.isCustomerOrderType ||
                                        row.isNotCustomerOrderType
                                          ? _c("span", [
                                              _vm._v(_vm._s(row.saleOrderId))
                                            ])
                                          : _c("span")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "客户名称" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        row.isCustomerOrderType
                                          ? _c("span", [
                                              _vm._v(_vm._s(row.customerName))
                                            ])
                                          : _c("span")
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "仓位" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.inventoryName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.createTime) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.applicantName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right",
                                              width: "300",
                                              trigger: "hover",
                                              "open-delay": "1000"
                                            },
                                            on: {
                                              show: function($event) {
                                                return _vm.checkApprovalProcess(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批人：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.fullName))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "block mt10 mb10"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      审批时间：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(
                                                      _vm._s(row.approvalTime)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批意见：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.content))
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "pointer",
                                                attrs: {
                                                  slot: "reference",
                                                  type: _vm._f("statusFilter")(
                                                    row.auditStatus
                                                  )
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(row.auditStatusName)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.auditStatus == "Draft" ||
                                        row.auditStatus == "Rejected"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-check",
                                                  attrs: {
                                                    type: "success",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "提交审批"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.submitHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.auditStatus == "Approved"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                row.reserveStatus == "Reserve"
                                                  ? _c("el-button", {
                                                      staticClass:
                                                        "el-icon-close",
                                                      attrs: {
                                                        type: "primary",
                                                        circle: "",
                                                        size: "mini",
                                                        title: "整单解除"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.removeHandle(
                                                            $index,
                                                            row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                        layout: "sizes, total, prev, pager, next"
                      },
                      on: {
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        },
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                }
              ],
              staticClass: "edit_part"
            },
            [
              _c(
                "div",
                { staticClass: "clearfix mb10" },
                [
                  _c(
                    "div",
                    { staticClass: "display_ib mr10" },
                    [
                      _vm._m(0),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10 mb10",
                          attrs: {
                            disabled: !_vm.isEmptyData,
                            placeholder: "请选择"
                          },
                          on: { change: _vm.reserveTypeChange },
                          model: {
                            value: _vm.editData.reserveType,
                            callback: function($$v) {
                              _vm.$set(_vm.editData, "reserveType", $$v)
                            },
                            expression: "editData.reserveType"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "请选择", value: "" }
                          }),
                          _vm._l(_vm.reserveTypeList, function(item) {
                            return _c("el-option", {
                              key: item.dictItemValue,
                              attrs: {
                                label: item.dictItemName,
                                value: item.dictItemValue
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "display_ib mr10" },
                    [
                      _vm._m(1),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10 mb10",
                          attrs: {
                            disabled: !_vm.isEmptyData,
                            placeholder: "请选择"
                          },
                          on: { change: _vm.inventoryChange },
                          model: {
                            value: _vm.editData.inventory,
                            callback: function($$v) {
                              _vm.$set(_vm.editData, "inventory", $$v)
                            },
                            expression: "editData.inventory"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "请选择", value: "" }
                          }),
                          _vm._l(_vm.inventoryTypeList, function(item) {
                            return _c("el-option", {
                              key: item.index,
                              attrs: { label: item.name, value: item.index }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.isCustomOrder
                    ? _c(
                        "div",
                        { staticClass: "display_ib mr10" },
                        [
                          _c("span", { staticClass: "tag" }, [
                            _vm.isCustomOrderRequired
                              ? _c("span", { staticClass: "red mr5" }, [
                                  _vm._v("*")
                                ])
                              : _vm._e(),
                            _vm._v("业务编号：")
                          ]),
                          _c("el-input", {
                            staticClass: "input_single w200 mr10 mb10",
                            attrs: {
                              disabled: !_vm.isEmptyData,
                              placeholder: "请输入"
                            },
                            model: {
                              value: _vm.editData.saleOrderId,
                              callback: function($$v) {
                                _vm.$set(_vm.editData, "saleOrderId", $$v)
                              },
                              expression: "editData.saleOrderId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isCustomOrder
                    ? _c("el-button", {
                        staticClass: "mr10",
                        attrs: {
                          disabled: !_vm.isEmptyData,
                          size: "small",
                          type: "primary",
                          icon: "el-icon-refresh-right",
                          circle: "",
                          title: "拉取数据"
                        },
                        on: { click: _vm.pullDataHandle }
                      })
                    : _vm._e(),
                  _vm.isCustomOrder && _vm.editData.customerName
                    ? _c("div", { staticClass: "display_ib mr10" }, [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("客户名称：")
                        ]),
                        _c("span", { staticClass: "tag" }, [
                          _vm._v(_vm._s(_vm.editData.customerName))
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "link inline_block lh36 fr ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportExcelTemplate }
                    },
                    [_vm._v("计划预留导入模板")]
                  ),
                  _c("el-button", {
                    staticClass: "fr",
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addEditHandle }
                  })
                ],
                1
              ),
              _c("upload-excel-component", {
                staticClass: "mb20",
                attrs: {
                  "on-success": _vm.importExcelSuccess,
                  "before-upload": _vm.importExcelBeforeUpload
                }
              }),
              _c(
                "div",
                { staticClass: "hidden" },
                [
                  _c("editTable", {
                    ref: "editTable",
                    attrs: {
                      "table-list": _vm.editData.planReserveItemList,
                      total:
                        _vm.editData.planReserveItemList &&
                        _vm.editData.planReserveItemList.length
                          ? _vm.editData.planReserveItemList.length
                          : 0,
                      "table-leader-fields-list": _vm.leaderFieldsList,
                      "table-tail-fields-list": _vm.tailFieldsList,
                      "table-fields-list": _vm.editFieldsList
                    },
                    on: {
                      deleteHandle: _vm.deleteEditItemHandle,
                      inputChange: _vm.inputChange,
                      selectChange: _vm.selectChange,
                      selectVisibleChange: _vm.selectVisibleChange,
                      datePickerChange: _vm.datePickerChange,
                      numberInputChange: _vm.numberInputChange
                    }
                  }),
                  !_vm.isEmptyData
                    ? _c(
                        "div",
                        { staticClass: "edit_btn_wrap mt20 fr" },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.cancelEditHandle } },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.isSaveDisabled,
                                type: "primary"
                              },
                              on: { click: _vm.saveEditHandle }
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.editItemStatus === "Approved" ||
                                  _vm.isSubmitDisabled,
                                type: "primary"
                              },
                              on: { click: _vm.submitEditHandle }
                            },
                            [_vm._v("提交审批")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "t_right mt20" },
                        [
                          _c(
                            "el-button",
                            { on: { click: _vm.cancelEditHandle } },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.isEmptyData,
                                type: "primary"
                              }
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.isEmptyData,
                                type: "primary"
                              }
                            },
                            [_vm._v("提交审批")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("预留类型：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("预留仓位：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }